<template>
  <div class="box box_column" style="height: 100%">
    <el-menu router :default-active="activeIndex" mode="horizontal" @select="handleSelect">
      <el-menu-item :index="item.path" v-for="item of urlList" :key="item.id">{{ item.name }}</el-menu-item>
    </el-menu>
    <div class="flex1">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "dataAnalysisView",
  data() {
    return {
      activeIndex: "/timeDomainAnalysis",
      urlList: [
        { id: 0, name: "时域统计值", path: "/timeDomainAnalysis" },
        { id: 8, name: "准静态时域统计值", path: "/staticTimeDomainAnalysis" },
        { id: 2, name: "频域分析", path: "/frequencyAnalysis" },
        { id: 1, name: "温度效应提取", path: "/temAnalysis" },
        { id: 3, name: "趋势分析", path: "/" },
        { id: 4, name: "相关性分析", path: "/relativeAnalysis" },
        { id: 5, name: "效应预测", path: "/effectPrediction" },
        { id: 6, name: "动位移分析", path: "/dynamicAnalysis" },
        // {id:7,name:'模态分析',path:'/modalAnalysis'},    //老版本
        { id: 7, name: "模态分析", path: "../model/modelIndex.vue" },
        { id: 9, name: "损伤识别", path: "/damageIdentify" }
      ]
    };
  },
  mounted() {
    var checkNav = sessionStorage.getItem("checkNav");
    var checkNav1 = JSON.parse(checkNav);
    console.log("checknav", checkNav1);
    this.urlList = checkNav1;

    this.activeIndex = sessionStorage.getItem("dataAnalysisIndex");
  },
  methods: {
    handleSelect(index) {
      console.log("数据分析", index);
      sessionStorage.setItem("dataAnalysisIndex", index);
    }
  },
  beforeDestroy() {
    sessionStorage.setItem("dataAnalysisIndex", "/timeDomainAnalysis");
  }
};
</script>

<style scoped></style>
